.about-background-image { 
    background-image: url('../Images/About/about1.jpg');
    height: 100%;   
    background-size: cover;
    height: 450px;
    margin-top: 5%;
}
.about-text {
  color: #FFFFFF;
  text-align: justify;
}
@media only screen and (min-width: 992px) {
  .about-text {
    padding-left:15%;
    padding-right: 15%;
  }
}
