.background-image { 
    background-size: cover;
    margin-top: 5%;
    object-fit: contain;
    -webkit-animation: mymove 17s infinite;
    animation: mymove 17s infinite;
}
.home-header{
  color:#FFFFFF; 
  text-align: center;
  margin-top: 25%;
  font-style: bold;
}
@-webkit-keyframes mymove {
  from {background-image: url('../Images/Home/home1.png')}
  to {background-image: url('../Images/Home/home3.jpg')}
}
@media only screen and (min-width: 992px) {
  .image-height {
    height: 653px;
  }
  .home-text {
    font-size: 400%;
  }
}
@media only screen and (max-width: 992px) {
    .image-height {
      height: 300px;
    }
    .home-header{
      margin-top: 40%;
    }
    .home-text {
      font-size: 130%;
  }
}