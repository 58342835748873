.work-background-image { 
  background-image: url('../Images/Work/office.jpg');
  height: 100%;   
  background-size: cover;
  height: 450px;
  margin-top: 5%;
}
.work-text {
  color: 	#FFFFFF;
  text-align: justify;
}
.work-lin-color {
  color:''
}
@media only screen and (min-width: 992px) {
  .work-text {
    padding-left:13%;
    padding-right: 13%;
  }
}