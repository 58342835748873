.container {
    padding: 15px;
}
.text-family {
    font-family: Helvetica;
}
.header-text {
    text-align: center;
    color: #F4A460;
    font-style: bold; 
    margin-top: 3%;
    margin-bottom: 3%;
}
@media only screen and (min-width: 992px) { 
    .header-size {
        font-size: 400%;
    }
}
@media only screen and (max-width: 992px) { 
    .header-size {
        font-size: 300%;
    }
    .other-header-size {
        font-size: 150%;
    }
}