.contact-text {
    text-align: justify;
    color: #74777a;
}
.linkedin-image {
    text-align: center;
    width: 45px !important;
    height: 45px;
}
.footer-text {
    color: #74777a;
    text-align: center;
}

@media only screen and (min-width: 992px) { 
    .contact-text {
        padding-left:12%;
        padding-right: 12%;
    }
}
@media only screen and (max-width: 992px) {
    .email-text {
        text-align: center;
    }
    .linkedin {
        text-align: center;
    }
}