.body {
  font-weight: normal;
  outline: none;
  position: relative;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 23px;
  color: #bfbfbf;
  background-color: #fff;
  margin: 8px;
  height:100%;
  width:100%
}

