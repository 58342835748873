.nav-link {
    color: #0000;
    text-align: center;
}
@media only screen and (min-width: 992px) {
    .nav-item {
        width: 92px;
        font-size: 90%;
    }
    .header-logo {
        height: 90px; 
        width: 100%; 
    }
    .nav-bar-margin {
        margin-top: 15px;
        padding-left: 54px;
    }
}
@media only screen and (max-width: 992px) {
    .nav-link {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nav-item {
        width:75px;
        font-size: 50%;
    }
    .header-logo {
        height: 50px; 
        width: 100%; 
    }
    .navbar {
        padding:1%;
    }
    .navbar-nav {
        width: 100%;
    }
}