.service-text {
    text-align: center;
    color: #569ed8;
    font-style: bold;
    margin-bottom:2%;
    font-size: 25px;
}
.service-image {
    margin-bottom: 4%; 
    border-radius: 50%;
    height:200px;
}
.service-box {
    margin-left: 14%;
    margin-right: 14%;
}
.sevice-footer {
    text-align: justify;
    color: #74777a;
    margin-top: 2%;
}
.service-contact {
    text-align: center;
    margin-bottom: 5%;
    color: #74777a;
}
@media only screen and (min-width: 992px) { 
    .service-image {
        margin-left: 27%;
        width:40%; 
    }
}
@media only screen and (max-width: 992px) { 
    .service-image {
        margin-left: 14%;
        width:65%; 
    }
    .service-box {
        margin-left: 4%;
        margin-right: 4%;
    }
}



