.customer-button {
    height: 50px ;
    background-color: #31d07a ;
    color: white ;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}
@media only screen and (min-width: 992px) {
    .customer-button {
        width: 15% ;
        margin-left: 20px;
        margin-right: 20px;
    }
    .customer-container {
        margin-left: 6%;
    }
}
@media only screen and (max-width: 992px) {
    .customer-button{
        width: 90% ;
        margin-top: 10px;
        margin-left: 4%;
        margin-right: 2%;
    }
}

